<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      sm="12"
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      />
        <v-autocomplete
          v-if="items"
          v-model="selectedID"
          :items="items"
          outlined
          chips
          small-chips
          :label="label"
          itemText="name"
          itemValue="id"
          hide-details
          @change="handleChange"
        />
    </v-col>
  </v-row>
</template>
<script>
import apiService from '@/services/api'
export default {
  props: {
    workspaceID: {
      type: String,
      reqired: true,
    },
    label: {
      type: String,
      default: 'ID',
    },
    value: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    loading: false,
    selectedID: null,
    items: null,
  }),
  watch: {
    value () {
      this.prepareValue()
    },
    type () {
      this.items = null
      this.handleGetData()
    },
  },
  mounted () {
    this.prepareValue()
    this.handleGetData()
  },
  methods: {
    prepareValue () {
      if (this.value !== this.selectedID) this.selectedID = this.value
    },
    handleGetData () {
      this.items = null
      this.handleGetContentTypes()
    },
    handleGetContentTypes () {
      this.loading = true
      apiService.getAllWithoutLimit ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/content-types/`)
        .then(response => {
          this.items = [{
            id: null,
            name: '',
          }, ...response.filter(x => x.Type === 'activityBarcelo').map(item => {
            return ({
              id: item.ID,
              name: item.Name || item.ID
            })
          })]
          this.loading = false
      })
      .catch (error => {
        console.log(error)
        this.loading = false
      })
    },
    handleChange () {
      if (this.value !== this.selectedID) this.onChange(this.selectedID)
    },
  }

}
</script>

